/* eslint-disable indent */
/* eslint-disable no-undef */
const ENV = {
    development: {
        eClinic: 'https://dr.e-doctor.dev',
        user: 'https://e-doctor.dev/pk',
        support: 'https://e-doctor.dev/pk/hotro',
    },
    beta: {
        eClinic: 'https://dr.edoctor-demo.com',
        user: 'https://edoctor-demo.com/pk',
        support: 'https://edoctor.io/pk/hotro',
    },
    production: {
        eClinic: 'https://pk.edoctor.io',
        user: 'https://edoctor.io/pk',
        support: 'https://edoctor.io/pk/hotro',
    },
}

export default function URLRedirect() {
    if (typeof window !== 'undefined') {
        const currentDomain = String(window?.location?.hostname).toLowerCase()
        if (/edoctor\.io$/.test(currentDomain)) {
            return ENV.production
        }
        if (/edoctor-demo\.com$/.test(currentDomain)) {
            return ENV.beta
        }
        return ENV.development
    }
}
